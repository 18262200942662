























































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  apiShopDetail,
  apiShopSuperAdd,
  apiShopSuperEdit,
  apiSetMealLists,
  apiGetCustomerListsNopage,
  apiBrandList,
  apiAddBrand,
} from '@/api/shop'
import config from '@/config'

import historyMeal from './components/history-meal.vue'
import { debounce } from '@/utils/util'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import MaterialSelect from '@/components/material-select/index.vue'

// 在 script 标签下添加接口定义
interface BrandOption {
  id: number;
  name: string;
}

@Component({
  components: {
    historyMeal,
    MaterialSelect,
  },
})
export default class ListsDetail extends Vue {
  /** S Data **/
  action = `${config.baseURL}/platformapi/upload/image`
  version = config.version

  identity: number | null = null // 当前编辑用户的身份ID

  setMealData: any = []

  // show_disables: boolean = false;

  // 添加商城表单数据
  form: any = {
    app_id: this.$route.query.appid,
    name: '', // 名称
    bsn: '',
    super_admin: '', // 账号
    super_password: '', // 密码
    super_password_confirm: '', // 确认密码
    status: 1, // 商合作状态 0-停止服务 1-开启服务
    contact: '', // 联系人
    contact_mobile: '', // 联系人手机号
    domain_alias: '', // 域名别名
    is_check_domain: 1, // 是否验证域名
    shop_url: '', // 当前域名
    set_meal_id: '', // 套餐ID
    expires_time: '', // 合同到期日
    remark: '', // 备注
    shop_data: {},
    business_id: '', //客户id
    company_name: '', //公司名称
    company_address: '', //公司地址
    company_bl_no: '', //证件号码
    company_bl_img: '', //证件
    password: '',
    checkPass: '',
    nsr_type: '',
    kh_yinhang: '',
    sz_yinhang: '',
    zh_yinhang: '',
    jy_fanwei: [],
    brand_list: [], // 券核销品牌列表
    // app_id: 11 //供应商id
  }

  options: any[] = []
  loading: boolean = false

  brandOptions: BrandOption[] = [] // 品牌选项
  brandLoading: boolean = false
  brandDialogVisible: boolean = false
  newBrand = {
    brand_name: ''
  }
  brandRules = {
    brand_name: [
      {
        required: true,
        message: '请输入品牌名称',
        trigger: 'blur'
      }
    ]
  }

  // 表单校验
  rules = {
    name: [{
      required: true,
      message: '输入商城名称',
      trigger: 'blur'
    }],
    bsn: [
      {
        required: true,
        message: '请输入客户平台编码',
        trigger: 'blur'
      },
    ],
    company_name: [
      {
        required: true,
        message: '请输入客户名称',
        trigger: 'blur'
      },
      {
        max: 20,
        message: '客户名称输入长度限制20个字符以内',
        trigger: 'blur',
      },
    ],
    company_address: [
      {
        required: true,
        message: '请输入司地址',
        trigger: 'blur'
      },
      {
        max: 50,
        message: '地址输入长度限制50个字符以内',
        trigger: 'blur',
      },
    ],
    company_bl_no: [
      {
        required: true,
        message: '请输入证件号码',
        trigger: 'blur'
      },
      {
        max: 24,
        message: '证件号码限24个字符以内',
        trigger: 'blur'
      },
    ],
    company_bl_img: [
      {
        required: true,
        message: '请上传证件',
        trigger: 'blur'
      },
    ],
    super_admin: [
      {
        required: true,
        message: '请输入商城名称',
        trigger: 'blur'
      },
    ],
    super_password: [
      {
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      },
      {
        validator: (rule: object, value: string, callback: any) => {
          !value ? callback(new Error('请输入密码')) : callback()
        },
        trigger: 'blur',
      },
    ],
    super_password_confirm: [
      {
        required: true,
        message: '请再次输入密码',
        trigger: 'blur'
      },
      {
        validator: (rule: object, value: string, callback: any) => {
          if (this.form.super_password) {
            if (!value) callback(new Error('请再次输入密码'))
            if (value !== this.form.super_password) {
              callback(new Error('两次输入密码不一致!'))
            }
          }
          callback()
        },
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          if (value === '' && !this.identity) {
            return callback()
          }
          if (value === '') {
            callback(new Error('请输入密码'))
          } else {
            if (value.length < 6) {
              return callback(new Error('密码长度不得低于6位'))
            }
            if (value.length > 18) {
              return callback(new Error('密码长度不得高于18位'))
            }
            const regExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
            if (!regExp.test(value)) {
              return callback(
                  new Error('密码至少要包含数字和字母')
              )
            }
            if (this.form.checkPass !== '') {
              (this.$refs.form as any).validateField('checkPass')
            }
            callback()
          }
        },
        trigger: 'blur',
      },
    ],
    checkPass: [
      {
        required: true,
        validator: (rule: any, value: any, callback: any) => {
          if (
              value === '' &&
              this.form.password === '' &&
              !this.identity
          ) {
            return callback()
          }
          if (value === '') {
            callback(new Error('请再次输入码'))
          } else if (value !== this.form.password) {
            callback(new Error('两次输入密码不一致!'))
          } else {
            callback()
          }
        },
        trigger: 'blur',
      },
    ],
    jy_fanwei: [
      {
        required: true,
        message: '请选择经营范围',
        trigger: ['blur', 'change'],
      },
      {
        validator: (rule: any, value: any, callback: any) => {
          if (value.includes('2')) {
            // 如果选择了券核销
            if (!this.form.brand_list || this.form.brand_list.length === 0) {
              callback(new Error('请至少选择一个券核销品牌'));
              return;
            }
            
            // 验证每个品牌的信息是否完整
            const invalidBrand = this.form.brand_list.find(
              (item: any) => !item.brand_id || 
              !item.discount_rate || 
              item.discount_rate <= 0
            );
            
            if (invalidBrand) {
              callback(new Error('请完善品牌信息，折扣率必须大于0'));
              return;
            }
          }
          callback();
        },
        trigger: ['blur', 'change']
      }
    ],
    expires_time: [
      {
        required: true,
        message: '请选择合同到期日',
        trigger: ['blur', 'change'],
      },
    ],
  }

  /** E Data **/

  /** S Methods **/
  handleSuccess (response: any, file: any, fileList: any[]) {
    if (response.code == 0) {
      this.$message.error({
        message: `文件上传失败,${response.msg}`,
        duration: 3000,
      })
      return
    }
    let allSuccess = fileList.every((item) => item.status == 'success')
    if (allSuccess) {
      this.form.company_bl_img = file.response.data.uri
      console.log(response, file, fileList)
    }
  }

  // 点击表单提交
  onSubmit (formName: string) {
    // 验证表单格式是否正确
    const refs = this.$refs[formName] as HTMLFormElement
    refs.validate((valid: boolean): any => {
      if (!valid) return

      if (!this.identity) {
        this.handleShopAdd()
      } else {
        this.handleShopEdit()
      }
    })
  }

  // 添加商城
  async handleShopAdd (): Promise<void> {
    await apiShopSuperAdd({ ...this.form })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 编辑商城
  async handleShopEdit (): Promise<void> {
    await apiShopSuperEdit({
      ...this.form,
      id: this.identity
    })
    setTimeout(() => this.$router.go(-1), 500)
  }

  // 获取详情
  async getShopDetailFunc (): Promise<void> {
    const res: any = await apiShopDetail({
      id: this.identity as number,
    })
    // console.log(res)
    // console.log(this.form)
    Object.keys(res).map((key) => {
      this.$set(this.form, key, res[key])
    })
    if(res.brandOptions.length > 0){
      this.brandOptions = res.brandOptions;
    }
    // this.show_disables = true
  }

  // 获取套餐列表
  async getSetMealLists (): Promise<void> {
    const { lists } = await apiSetMealLists({ status: 1 })
    this.setMealData = lists
  }

  //获取客户
  async getCustomerListsNopage (query: string): Promise<void> {
    this.loading = true
    let params: any = {
      app_id: this.$route.query.appid,
    }
    if (query != '') params = { company_name: query, ...params }
    const res = await apiGetCustomerListsNopage({ ...params }).finally(
        () => {
          this.loading = false
        }
    )

    this.options = res
  }

  // 添加品牌行
  addBrandRow () {
    this.form.brand_list.push({
      brand_id: '',
      brand_name: '',
      discount_rate: 0.01
    })
  }

  // 删除品牌行
  removeBrandRow (index: number) {
    this.form.brand_list.splice(index, 1)
    // 如果选择了券核销且删除后没有品牌了，触发验证
    if (this.form.jy_fanwei.includes('2')) {
      this.$nextTick(() => {
        (this.$refs.form as any).validateField('jy_fanwei')
      })
    }
  }

  // 搜索品牌
  async searchBrands (query: string) {
    this.brandLoading = true
    try {
      if (query) {
        // 调用获取品牌列表接口
        const res = await apiBrandList({ keyword: query })
        this.brandOptions = res.lists || []
      } else {
        this.brandOptions = []
      }
    } catch (error) {
      console.error(error)
      this.brandOptions = []
    } finally {
      this.brandLoading = false
    }
  }

  // 品牌选择改变
  handleBrandChange (brandId: number, index: number) {
    // 如果已经选择过该品牌，则不允许选择
    if (this.form.brand_list.some((item: any, idx: number) => item.brand_id === brandId && idx !== index)) {
      this.$message.warning('该品牌已经选择过了')
      this.form.brand_list[index].brand_id = ''
      this.form.brand_list[index].brand_name = ''
      return
    }

    const selectedBrand = this.brandOptions.find(item => item.id === brandId)
    if (selectedBrand) {
      this.form.brand_list[index].brand_name = selectedBrand.name
      // 触发验证
      this.$nextTick(() => {
        (this.$refs.form as any).validateField('jy_fanwei')
      })
    }
  }

  // 显示新增品牌弹窗
  showAddBrandDialog () {
    this.brandDialogVisible = true
    this.newBrand.brand_name = ''
  }

  // 提交新品牌
  async submitNewBrand () {
    const brandForm = this.$refs.brandForm as any
    await brandForm.validate(async (valid: boolean) => {
      if (valid) {
        try {
          // 调用新增品牌接口
          apiAddBrand(this.newBrand)
            .then((res: any) => {
              this.$message.success('添加成功')
              this.brandDialogVisible = false
              console.log(res, 'resres')
              // 将新增的品牌添加到选项中
              this.brandOptions.push({
                id: res.id,
                name: this.newBrand.brand_name
              })
              // 清空表单
              this.newBrand.brand_name = ''
            })
            .catch(() => {

            })

        } catch (error) {
          this.$message.error('添加失败')
        }
      }
    })
  }

  // 判断选项是否已被选择
  isOptionDisabled(brandId: number): boolean {
    return this.form.brand_list.some(item => item.brand_id === brandId)
  }

  // 添加折扣率变化时的验证方法
  validateJyFanwei() {
    this.$nextTick(() => {
      (this.$refs.form as any).validateField('jy_fanwei');
    });
  }

  /** E Methods **/

  /** S Life Cycle **/
  created () {
    const query: any = this.$route.query
    // this.form.app_id = 11;
    this.getCustomerListsNopage('')
    // 编辑模式：初始化数据
    this.identity = +query.id
    if (+query.id) {
      this.getShopDetailFunc()
    }

    this.onSubmit = debounce(this.onSubmit, 300)
    this.getSetMealLists()
  }

  /** E Life Cycle **/

  @Watch('form.jy_fanwei')
  onJyFanweiChange (newVal: string[]) {
    if (!newVal.includes('2')) {
      this.form.brand_list = []
    } else if (this.form.brand_list.length === 0) {
      // 如果选择了券核销但没有品牌，添加一个空行
      this.addBrandRow()
    }
    // 触发验证
    this.$nextTick(() => {
      (this.$refs.form as any).validateField('jy_fanwei')
    })
  }
}
